import Button from 'components/Button';
import React from 'react';

import { Icon } from '@just-ai/just-ui/dist/Icon';

import cn from 'classnames';
import { t } from 'localization';
import styles from './styles.module.scss';

interface FullScreenToggleInterface {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  className?: string;
}

const FullScreenToggle = ({ isFullScreen, toggleFullScreen, className }: FullScreenToggleInterface) => {
  return (
    <Button
      flat
      className={cn(styles.toggleFullScreen, className)}
      onClick={toggleFullScreen}
      data-test-id='chatTestWidgetFullScreenButton'
    >
      {isFullScreen ? (
        <>
          <Icon name='farWindowMinimize' size='sm' />
          <span>{t('WidgetComponent:Compress')}</span>
        </>
      ) : (
        <>
          <Icon name='farWindowMaximize' size='sm' />
          <span>{t('WidgetComponent:FullScreen')}</span>
        </>
      )}
    </Button>
  );
};

export default React.memo(FullScreenToggle);
