import cn from 'classnames';
import { Button } from '@just-ai/just-ui';
import { useAuthContext } from 'contexts/AuthContext';

import { t } from '../../../../localization';

import styles from './styles.module.scss';

export const SelectAccountButton = () => {
  const { ccAccountId, allowedAccounts, invitations, redirectToSelectAccount, isAuthenticated } = useAuthContext();

  const currentAccountName = allowedAccounts?.find(account => account.id === ccAccountId)?.name;

  if (!allowedAccounts?.length || allowedAccounts.length < 2 || !isAuthenticated) {
    return null;
  }

  return (
    <div className={styles.SelectAccount}>
      <Button
        outline
        color='secondary'
        className={cn(styles.SelectAccount__button, { [styles.notification]: !!invitations?.length })}
        iconRight='farArrowRight'
        onClick={redirectToSelectAccount}
        data-test-id='goToAccountSelect'
      >
        <small className={styles.SelectAccount__text}>{t('AccountChangeButton', { currentAccountName })}</small>
      </Button>
    </div>
  );
};
