import axios from 'axios';
import { isDev, isNullOrUndefined } from 'utils';
import { AccountDataDto, OptionsResponseDto } from './types';

export const userPermissionsCheck = (userPermissions?: string[], permissionsToCheck?: string[]) => {
  if (!permissionsToCheck?.length) return true;
  if (!userPermissions?.length) return false;
  return permissionsToCheck.reduce((permissionCheck, permission) => {
    if (!permissionCheck) return false;
    return !!userPermissions?.some(userPermission => userPermission.toLowerCase() === permission.toLowerCase());
  }, true);
};

const getConversationCloudLink = (route = '', redirectPathname?: string) => {
  let { origin = '' } = window.location;
  const hostname = isDev() ? 'https://localhost:3001' : origin;
  const authPath = hostname + route;
  return `${authPath}${redirectPathname ? `?redirectUrl=${origin}${redirectPathname}` : ''}&theme=caila`;
};

export const redirectToConversationCloud = (route = '', redirectPathname?: string) => {
  window.location.href = getConversationCloudLink(route, redirectPathname);
};

export const login = (redirectPathname?: string) => {
  redirectToConversationCloud(`/c/login`, redirectPathname);
};

export const loginWithAccountPageRedirect = () => {
  redirectToConversationCloud('/c/login');
};

export const getLoginLink = (redirectPathname?: string) => getConversationCloudLink('/c/login', redirectPathname);

export const register = (redirectPathname?: string) => {
  redirectToConversationCloud('/c/register', redirectPathname);
};

export const getRegisterLink = (redirectPathname?: string) => getConversationCloudLink('/c/register', redirectPathname);

export const getAllowedAccounts = async (userId: number, productName: string) => {
  const { data } = await axios.get(`/api/accountsadmin/c/users/${userId}/allowed-accounts?product=${productName}`);
  return data as AccountDataDto[];
};

export const checkIfUserIsSAdmin = (userPermissions?: string[], accountId?: number) => {
  return (
    !!userPermissions?.some(userPermission => userPermission.toUpperCase() === 'ACCOUNTS_ADMIN_WRITE') &&
    isNullOrUndefined(accountId)
  );
};

export const accounsadminAuthentication = async () => {
  const { data } = await axios.get('/api/accountsadmin/c/authorization/check-authorized');
  return data;
};

export const getAccountInvitations = async (userId: number) => {
  const { data } = await axios.get(`/api/accountsadmin/c/account-invitation/get-by-user?userId=${userId}`);
  return data;
};

export const getCcOptions: () => Promise<OptionsResponseDto> = async () => {
  const { data } = await axios.get('/api/accountsadmin/public/options');
  return data;
};
