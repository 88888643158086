import React from 'react';
import { Helmet } from 'react-helmet';

const CrispInjector: React.FC = () => {
  let websiteId: string | null = null;
  const hostname = window.location.hostname;
  if (hostname.includes('caila.io')) {
    websiteId = 'e64433cf-a2e5-4e57-b012-1613bd2fd2d9';
  } else if (hostname.includes('caila.immers.cloud')) {
    websiteId = '81118cc1-f120-4498-9f01-c0e73a27d84e';
  }

  if (!websiteId) return null;

  return (
    <Helmet>
      <script type='text/javascript'>
        {`(function () {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "${websiteId}";
        d = document;
        s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();`}
      </script>
    </Helmet>
  );
};

export default React.memo(CrispInjector);
