import { lazy, Suspense, useMemo } from 'react';
import { Redirect, Switch } from 'react-router';

import RouteWithLayout from 'components/RouteWithLayout';

import SimpleLayout from 'layouts/Simple';
import FullLayout from 'layouts/Full';

import { useAuthContext } from 'contexts/AuthContext';
import PrivateRoute from 'components/PrivateRoute';
import EnhancedRoute from 'components/EnhancedRoute';
import { PrivateContexts } from 'contexts/containers/PrivateContexts';
import { Spinner } from 'components/Spinner';
import { CATALOG_BASE_PATH } from 'modules/ServicesCatalog/routesConfig';

const SwaggerUIPage = lazy(() => import('modules/Account/pages/SwaggerUIPage'));
const AccountPage = lazy(() => import('modules/Account'));
const ServicesCatalogPage = lazy(() => import('modules/ServicesCatalog'));
const SuperadminManagerPage = lazy(() => import('modules/SuperadminManager'));
const MlpAppContainerPage = lazy(() => import('modules/MlpAppContainer'));
const MlpAppAuthPage = lazy(() => import('modules/MlpAppAuth'));
const JobStatusPage = lazy(() => import('modules/JobStatus'));
const LandingPage = lazy(() => import('modules/LandingPage'));

export const WORKSPACE_BASE_PATH = 'workspace';

export const AppRouter = () => {
  const { isAuthenticated, isUserSuperadmin, userHasServices } = useAuthContext();
  const redirectRoute = useMemo(
    () =>
      isAuthenticated
        ? isUserSuperadmin
          ? '/superadmin'
          : `/${userHasServices ? WORKSPACE_BASE_PATH : CATALOG_BASE_PATH}`
        : '/',
    [isAuthenticated, isUserSuperadmin, userHasServices]
  );
  return (
    <Suspense fallback={<Spinner zIndex={1000} />}>
      <Switch>
        {!isAuthenticated && <RouteWithLayout path='/' exact component={LandingPage} layout={FullLayout} />}
        <RouteWithLayout path='/swagger-ui' exact component={SwaggerUIPage} layout={SimpleLayout} />
        <RouteWithLayout path='/landing' exact component={LandingPage} layout={FullLayout} />
        <EnhancedRoute path={`/${CATALOG_BASE_PATH}/`} component={ServicesCatalogPage} layout={FullLayout} />
        <EnhancedRoute path='/app/:account/:appName' component={MlpAppContainerPage} layout={FullLayout} />
        <EnhancedRoute path='/app/auth' component={MlpAppAuthPage} layout={FullLayout} />
        <PrivateRoute path={[`/${WORKSPACE_BASE_PATH}`, '/superadmin', '/job-status']}>
          <PrivateContexts>
            <Switch>
              <EnhancedRoute
                path={`/${WORKSPACE_BASE_PATH}/`}
                component={AccountPage}
                layout={FullLayout}
                accesFor={['user']}
              />
              <EnhancedRoute
                path='/superadmin/'
                component={SuperadminManagerPage}
                layout={FullLayout}
                accesFor={['superadmin']}
              />
              <EnhancedRoute
                path='/job-status/'
                component={JobStatusPage}
                layout={FullLayout}
                accesFor={['superadmin', 'user']}
              />
            </Switch>
          </PrivateContexts>
        </PrivateRoute>
        <Redirect push to={redirectRoute} />
      </Switch>
    </Suspense>
  );
};
