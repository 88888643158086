import React from 'react';
import { Helmet } from 'react-helmet';
import CrispInjector from './components/CrispInjector';
import GTMInjector from './components/GTMInjector';

interface AppHelmetInterface {
  isTovieMLP: boolean;
  canonicalHref?: string;
}

const AppHelmet = ({ isTovieMLP, canonicalHref }: AppHelmetInterface) => {
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content={
            isTovieMLP
              ? 'A platform for hosting ML services. API for working with ML models.'
              : 'Платформа для хостинга ML-сервисов. API для работы с ML-моделями'
          }
          data-react-helmet='true'
        />
        <meta
          property='og:description'
          content={
            isTovieMLP
              ? 'A platform for hosting ML services. API for working with ML models.'
              : 'Платформа для хостинга ML-сервисов. API для работы с ML-моделями'
          }
          data-react-helmet='true'
        />
        <meta property='og:url' content={canonicalHref || 'https://caila.io'} />
        <meta
          property='og:title'
          content={isTovieMLP ? 'Tovie ML Place' : 'Caila | MLOps платформа. API-сервер для ML-моделей'}
          data-react-helmet='true'
        />
        {canonicalHref && <link rel='canonical' href={canonicalHref} data-react-helmet='true' />}
        <link rel='icon' href={!isTovieMLP ? '/favicon.ico' : '/favicon_tovie.ico'} type='image/x-icon' />
        <title>{isTovieMLP ? 'Tovie ML Place' : 'Caila | MLOps платформа. API-сервер для ML-моделей'}</title>
      </Helmet>
      <GTMInjector />
      <CrispInjector />
    </>
  );
};

export default React.memo(AppHelmet);
