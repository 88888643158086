import { parseError } from 'utils';
import { LogLevel, LogTransport, RichLogEvent } from '../index';
import { notifyActionMessage } from 'components/MessageContainer';

export class NotificationTransport implements LogTransport {
  constructor(public level: LogLevel) {}

  errorSources = new Set<string>();

  handleError(error: any, useThrottle = false) {
    const errorMessage = parseError(error);
    if (!useThrottle) {
      notifyActionMessage(errorMessage, { type: 'error' });
    } else if (!this.errorSources.has(errorMessage)) {
      this.errorSources.add(errorMessage);
      setTimeout(() => this.errorSources.delete(errorMessage), 30000);
      notifyActionMessage(errorMessage, { type: 'error' });
    }
  }

  log(event: RichLogEvent, context?: any) {
    switch (event.level) {
      case LogLevel.FATAl:
      case LogLevel.ERROR:
        this.handleError(event.exception, context?.useThrottle);
        break;
      case LogLevel.WARN:
        if (event.message)
          notifyActionMessage(event.message, {
            type: context?.type || 'warning',
            theme: context?.theme,
          });
        break;
      case LogLevel.LOG:
        break;
      case LogLevel.INFO:
        if (event.message)
          notifyActionMessage(event.message, {
            type: context?.type || 'info',
            theme: context?.theme,
          });
        break;
      case LogLevel.DEBUG:
        break;
      default:
        break;
    }
  }
}
