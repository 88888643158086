import React, { Ref } from 'react';
import cn from 'classnames';

import { InputText, TextInputProps } from '@just-ai/just-ui/dist/InputText';

import { t } from 'localization';
import { withMaskInput } from 'HOC/withMaskInput';

import './style.scss';

export interface InputInterface extends TextInputProps {
  value: string;
  className?: string;
  title?: string;
  type?: 'text' | 'email' | 'password' | 'number';
  placeholder?: string;
  require?: boolean;
  onBlur?: () => void;
  hasError?: boolean;
  errorText?: string;
  innerRef?: Ref<HTMLElement>;
}
const Input = function (
  {
    title,
    value,
    onChange,
    type,
    require,
    placeholder,
    errorText,
    hasError,
    innerRef,
    className,
    ...props
  }: InputInterface,
  ref: Ref<HTMLDivElement>
) {
  return (
    <div
      className={cn(
        'cmp-input',
        {
          'cmp-input_error': hasError,
        },
        className
      )}
    >
      {title} {require && <span className='cmp-input__require-label'> – {t('Input:Required')}</span>}
      <InputText
        {...props}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        value={value}
        innerRef={innerRef}
      />
      {errorText && <span className='cmp-input__error-box'>{errorText}</span>}
    </div>
  );
};

export default React.memo(withMaskInput(React.forwardRef(Input))) as typeof Input;
